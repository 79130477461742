.movie-title {
	color: white;
	display: flex;
	justify-content: center;
	text-align: center;
	padding: 1%;
}

.comment{
    color: white;
	display: flex;
	justify-content: center;
	margin-top: 10px;
	padding: 0.5%;
    cursor: pointer;
}