@import url(https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed);

* {
	margin: 0;
	font-family: "Roboto", sans-serif;
	/* font-family: "Barlow Semi Condensed"; */
}

html {
	height: -webkit-fill-available;
	/* We have to fix html height */
}

body {
	min-height: 100vh;
	position: relative;
	background-color: #200d32;
	background-image: url("../images/OEJ_bg_hd_no-grain.svg");;
	background-position: center;
	background-size: cover; /* scales the image until it has met the actual height / width /*
	/* background-image: linear-gradient(
		43deg,
		#200d32 0%,
		#282659 46%,
		#200d32 100%
	); */
	background-attachment: fixed;
}

a {
	text-decoration: none;
	color: #000;
}

.content-wrap {
	padding-bottom: 50px;
	/* Height of the footer */
}

.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 50px;
	/* Height of the footer */
	width: 100%;
	background-color: rgb(245, 245, 245);
	box-shadow: 0px -2px 6px -1px rgb(0 0 0 / 20%),
		0px -4px 8px 0px rgb(0 0 0 / 14%), 0px -1px 15px 0px rgb(0 0 0 / 12%);
}

.footerText {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* for image upload */

input[type="file"] {
	display: none;
}

.styled-image-upload {
	font-family: "Roboto", sans-serif;
	cursor: pointer;
}
