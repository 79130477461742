.container {
	overflow: hidden;
}

.newsletter {
	position: relative;
	top: 650px;
	width: 100%;
	height: 150px;
	background: rgba(0, 0, 0, 0.603);
	color: white;
	-webkit-transition: top 0.5s; /* For Safari 3.1 to 6.0 */
	transition: top 0.5s;
	margin-top: -150px;
}

.container:hover .newsletter {
	top: 450px;
}
